import Glide from '@glidejs/glide';

if (document.querySelector(".glide-podcasts")) {
  let glidePodcasts = new Glide('.glide-podcasts', {
    type: 'carousel',
    perView: 1,
    startAt: 0,
    focusAt: 'center',
    peek: {
      before: 55,
      after: 55,
    },
    gap: 18,
  });
  glidePodcasts.mount();
}


if (document.querySelector(".glide-features")) {
  let glideFeatures = new Glide('.glide-features', {
    type: 'carousel',
    perView: 1,
    startAt: 0,
    focusAt: 'center',
    peek: {
      before: 55,
    after: 55,
    },
    gap: 18,
  });
  glideFeatures.mount();
}

